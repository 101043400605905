import { ActionTypes } from '../constants/action-types.enum';
import { ActivityInterface } from '../interfaces/activity.interface';
import { localStorageService } from '../services/localStorageService';

const ActivityReducer = (
    state = loadInitialState(),
    action: { type: ActionTypes; payload: Partial<ActivityInterface> },
): ActivityInterface => {
    switch (action.type) {
        case ActionTypes.START_ACTIVTY:
        case ActionTypes.JOINED_CLASS_SYNC_ACTIVITY:
        case ActionTypes.RESPONSE_SUBMITTED:
        case ActionTypes.CLOSE_SUBMISSION:
        case ActionTypes.VOTING_STARTED:
        case ActionTypes.STARRED_RESPONSE:
        case ActionTypes.RESPONSE_DELETED:
        case ActionTypes.VOTING_RESPONSES_UPDATED:
        case ActionTypes.VOTING_STOPPED:
            return {
                ...state,
                ...action.payload,
            };
        case ActionTypes.END_ACTIVITY:
        case ActionTypes.LEFT_CLASS:
            return activityInitialState;
        default:
            return state;
    }
};

export const activityInitialState: ActivityInterface = {
    activityStatus: null,
    activityProps: null,
    presenterReply: '',
    submittedResponses: [],
    votingResponses: [],
    votingNumber: 0,
    previousResponseData: '',
};

const loadInitialState = (): ActivityInterface => {
    const cachedStore = localStorageService.getStoreData();
    if (cachedStore && cachedStore.activity) return cachedStore.activity;
    return activityInitialState;
};

export default ActivityReducer;
