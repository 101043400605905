import { Fragment, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import 'react-quill/dist/quill.snow.css';

import { activityActions } from '../../../../../../actions/activity.actions';

import ResponseToSubmitDto from '../../../../../../dtos/response-to-submit.dto';
import { ActivityPropsInterface, ActivityStatusTypes } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';

import ShortAnswerTextBox from './ShortAnswerTextBox';

function ShortAnswerInput() {
    const dispatch = useDispatch();
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const numOfSubmissionsAllowed = activityProps.numOfSubmissionsAllowed as number;
    const submittedResponses = activity.submittedResponses;
    const [isShowAddNewButton, setIsShowAddNewButton] = useState(submittedResponses.length > 0);
    const [errorMessage, setErrorMessage] = useState('');
    const [textValue, setTextValue] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setErrorMessage('');
    }, [activity.activityProps]);

    const shortAnswerSubmit = async () => {
        if (checkValidity()) {
            setIsShowAddNewButton(true);
            const responseToSubmit: ResponseToSubmitDto = {
                activityId: activityProps.activityId,
                activityType: activityProps.activityType,
                responseId: 'resp-' + v4(),
                responseData: textValue,
            };
            dispatch(activityActions.submitResponse(responseToSubmit));
        }
    };

    const checkValidity = useCallback(() => {
        const textValue = document.getElementsByClassName('ql-editor')[0].innerHTML;
        const cleanText = textValue.replace(/<\/?[^>]+(>|$)/g, '').trim();

        if (cleanText.length === 0 || cleanText === '<p><br></p>') {
            setErrorMessage(t(`lang_activity.err_response_cannot_empty`));
            return false;
        }

        if (cleanText.length > 1500) {
            setErrorMessage(t(`lang_activity.err_max_word_length_1500`));
            return false;
        }

        setErrorMessage('');
        setTextValue(textValue);
        return true;
    }, []);

    return (
        <>
            {activity.activityStatus === ActivityStatusTypes.submitting &&
                submittedResponses.length < numOfSubmissionsAllowed && (
                    <Fragment>
                        {submittedResponses.length > 0 && isShowAddNewButton ? (
                            <div className="short_ans_form">
                                <div className="answer_area"></div>
                                <div className="sh_btn">
                                    <Button variant="contained" onClick={() => setIsShowAddNewButton(false)}>
                                        {t(`lang_activity.btn_add_new`)}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="short_ans_form">
                                <div className="error_sec">
                                    <Typography variant="caption" className="notranslate">
                                        {errorMessage}
                                    </Typography>
                                </div>
                                <div className={errorMessage ? 'jodit_editor error' : 'jodit_editor'}>
                                    <ShortAnswerTextBox
                                        checkValidity={checkValidity}
                                        defaultValue={activity.previousResponseData || ''}
                                    />
                                </div>
                                <div className="sh_btn">
                                    <Button variant="contained" onClick={() => shortAnswerSubmit()}>
                                        {t(`lang_activity.btn_submit`)}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
        </>
    );
}
export default ShortAnswerInput;
