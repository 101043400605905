export enum ActivityStatusTypes {
    submitting = 'submitting',
    submitted = 'submitted',
    showingCorrect = 'showingCorrect',
    voting = 'voting',
}

export interface ActivityPropsInterface {
    email: string;
    activityId: string;
    activityType: string;
    activitySlideUrl: string;
    mcChoices?: string[];
    mcIsAllowSelectMultiple?: boolean;
    mcCorrectAnswers?: string[];
    numOfSubmissionsAllowed?: number;
    isCaptionRequired?: boolean;
    isNamesHidden?: boolean;
    fbNumOfBlanks?: number;
    fbCorrectAnswers?: string[];
    qpChoices?: string[];
    qpChartType?: string;
    qpLabel?: string;
    qpImage?: string;
}

export interface SubmittedResponseInterface {
    activityId: string;
    activityType: string;
    responseId: string;
    responseData: string;
}

export interface ActivityInterface {
    activityStatus: null | ActivityStatusTypes;
    activityProps: ActivityPropsInterface | null;
    presenterReply: string;
    submittedResponses: SubmittedResponseInterface[];
    votingResponses: Array<any>;
    votingNumber: number;
    previousResponseData?: string;
}
